<template>
    <div>
        <!-- 页头部分 -->
        <div class="max_title">
            <div class="max_content">
                <div>
                    欢迎访问住宅维修资金管理系统！ {{currentTime }}
                </div>
                <div>
                    <!--                    <span style="margin-right: 5px;" @click="toIndex($event)">设为首页</span> |-->
                    <span style="margin-left: 5px;" @click="addFavorite">加入收藏</span>
                </div>
            </div>
        </div>

        <div class="content" style="margin-bottom: 20px;">
            <!-- logo部分 -->
            <el-row class='logo' type='flex' align='middle'>
                <el-col :span="15">
                    <div class="main_title">
                        <h3>{{textTitle}}</h3>
                        <p>Housing Maintenance Fund Cloud management platform</p>
                    </div>
                </el-col>


                <el-col :span="9" v-show="showTab">
                    <div class="main_head">
                        <span style="cursor: pointer;" @click="upPwd">修改密码</span>
                        <el-dropdown @command="handleCommand">
                          <span class="el-dropdown-link">
                            <img src="@/assets/images/geren.png">
                              <span class="name" >{{nickName}}</span>
                          </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="info">个人信息</el-dropdown-item>
                                <!--                                <el-dropdown-item command="qy">企业信息维护</el-dropdown-item>-->
                                <el-dropdown-item command="logout">退出</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </el-col>
            </el-row>
            <!-- 蓝色背景部分 -->
            <div class='blue_bg' v-show="showTab" @mouseleave="shure_active">
                <div v-for="(item,index) in head_list" :key="index" @mouseleave="hideSecond"
                     @mouseenter="tebStyle(index)">
                    <div :class="indexTab==index?'tab_active':''">
                        <router-link :to="{name:item.url}" tag="p">
                            {{item.name}}
                        </router-link>
                        <ul class="second_tab" v-show="indexTab==index&&tabNum==true">
                            <router-link v-for="(item1,index1) in item.child" :key="index1"
                                         :to="{name:item1.url}"
                                         tag="li"
                                         :class="indexSecondTab==index1?'active_second':''"
                                         @mouseenter.native="tebSecondStyle(index1)">
                                {{item1.name}}
                            </router-link>
                        </ul>
                    </div>
                </div>

            </div>
        </div>

        <!--    内容部分-->
        <router-view/>


        <!--        底部导航-->
        <div class="foot">
<!--            <p>版权所有甘肃环讯信息科技有限公司</p>-->
            <p>
<!--                备案编号：0000000 -->
                技术支持：甘肃环讯信息科技有限公司
                0931-8121096
            </p>
        </div>

    </div>
</template>

<script>
    import qyInfo from "../components/common/qyInfo";
    import urljson from "../static/json/url.json";
    export default {
        name: "index",
        data() {
            return {
                currentTime: new Date().getFullYear() + '年' + (new Date().getMonth() + 1) + '月' + new Date().getDate() + '日 ' +
                    new Date().getHours() + ":" + (new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()), // 获取当前时间
                head_list: [],
                conf: '',
                indexTab: 0,
                tabNum: false,
                indexSecondTab: 0,
                showTab: false,
                url: this.$Config.base_server,
                nickName: "",//用户名
                textTitle:"住宅维修资金云管理平台",
                type: 0
            };
        },
        created() {
            var _this = this; //声明一个变量指向Vue实例this，保证作用域一致
            this.timer = setInterval(function () {
                _this.currentTime = //修改数据date
                    new Date().getFullYear() +
                    "年" +
                    (new Date().getMonth() + 1) +
                    "月" +
                    new Date().getDate() +
                    "日 " +
                    new Date().getHours() +
                    ":" +
                    (new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes())
            }, 1000);



        },
        beforeDestroy() {
            if (this.timer) {
                clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
            }
        },
        mounted() {
            const that = this;
            debugger;
          if (localStorage.getItem("token") != "" ||  localStorage.getItem("token") != null) {
                let urldata=urljson;
                var url = window.location.href;
                //循环
                urldata.forEach((value,key)=>{
                    if (url.indexOf(value.key)>=0){
                        that.textTitle=value.name;
                        return false;
                    }
                });
            }else{
                that.textTitle="住宅维修资金云管理平台测试环境";
            }
            if (localStorage.getItem("token") != "" && localStorage.getItem("token") != null) {
                that.showTab = true;
            }
            if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null) {
                that.nickName = JSON.parse(localStorage.getItem("user")).nickname;
            }
            if (JSON.parse(localStorage.getItem("user"))) {
                if (JSON.parse(localStorage.getItem("user")).type == "0") {
                    this.head_list = this.$Config.wuYe.menus;
                } else if (JSON.parse(localStorage.getItem("user")).type == "1") {
                    this.head_list = this.$Config.kaiFaShang.menus;
                } else if (JSON.parse(localStorage.getItem("user")).type == "2") {
                    this.head_list = this.$Config.YZWeiYuan.menus;
                } else if (JSON.parse(localStorage.getItem("user")).type == "3") {
                    this.head_list = this.$Config.YZ.menus;
                }
            }

            window.addEventListener('setItem', (e) => {
                if (e.key == "token") {
                    if (e.newValue != "") {
                        localStorage.setItem("token", e.newValue)
                        that.showTab = true;
                    } else {
                        that.showTab = false;
                    }
                }

                if (e.key == "user") {
                    that.nickName = JSON.parse(e.newValue).nickname;
                    if (JSON.parse(e.newValue).type == "0") {//物业用户
                        that.type = 0;
                        this.head_list = this.$Config.wuYe.menus;
                    } else if (JSON.parse(e.newValue).type == "1") {//开发商用户
                        that.type = 1;
                        this.head_list = this.$Config.kaiFaShang.menus;
                    } else if (JSON.parse(e.newValue).type == "2") {//业主委员会登录
                        that.type = 2;
                        this.head_list = this.$Config.YZWeiYuan.menus;
                    } else if (JSON.parse(e.newValue).type == "3") {//业主用户
                        that.type = 3;
                        this.head_list = this.$Config.YZ.menus;
                    } else if (JSON.parse(e.newValue).type == "4") {//系统用户
                        that.type = 4;
                    }
                }


            });
            //菜单选项

            this.shure_active()//确定选中哪个菜单
        },
        methods: {
            shure_active() {
                let user = JSON.parse(localStorage.getItem("user")).type;
                if (user == "0") {//物业
                    this.forEachMenu(this.$Config.wuYe.menus)
                } else if (user == "1") {//开发商
                    this.forEachMenu(this.$Config.kaiFaShang.menus)
                } else if (user == "2") {//业主委员会
                    this.forEachMenu(this.$Config.YZWeiYuan.menus)
                } else if (user == "3") {//业主
                    this.forEachMenu(this.$Config.YZ.menus)
                }

            },
            forEachMenu(arr) {
                arr.forEach((item, index) => {
                    if (item.url == this.$route.name) {
                        this.indexTab = index;
                    }
                })
            },
            hideSecond() {
                this.tabNum = false;
            },
            tebSecondStyle(index) {
                this.indexSecondTab = index;
            },
            tebStyle(index) {
                this.tabNum = true;
                this.indexTab = index;
            },
            toIndex(element) {
                let obj = element.currentTarget;
                let vrl = window.location;
                try {
                    obj.style.behavior = 'url(#default#homepage)';
                    obj.setHomePage(vrl);
                } catch (e) {
                    if (window.netscape) {
                        try {
                            netscape.security.PrivilegeManager.enablePrivilege("UniversalXPConnect");
                        } catch (e) {
                            alert(
                                `此操作被浏览器拒绝！请按照下面步骤操作：1，打开浏览器选项。2，在搜索栏中搜索主页。3，将${vrl}填写即可`
                            );
                        }
                        var prefs = Components.classes['@mozilla.org/preferences-service;1'].getService(Components.interfaces.nsIPrefBranch);
                        prefs.setCharPref('browser.startup.homepage', vrl);
                    } else {
                        alert("您的浏览器不支持，请按照下面步骤操作：1.打开浏览器设置。2.启动时选择打开特定网页或一组网页。3.输入：" + vrl + "点击确定。");
                    }
                }
            },
            addFavorite() {
                var url = window.location;
                var title = document.title;
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf("360se") > -1) {
                    alert("由于360浏览器功能限制，请按 Ctrl+D 手动收藏！");
                } else if (ua.indexOf("msie 8") > -1) {
                    window.external.AddToFavoritesBar(url, title); //IE8
                } else if (document.all) { //IE类浏览器
                    try {
                        window.external.addFavorite(url, title);
                    } catch (e) {
                        alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
                    }
                } else if (window.sidebar) { //firfox等浏览器；
                    if (window.sidebar.addPanel) {
                        window.sidebar.addPanel(title, url, "");
                    } else {
                        alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
                    }

                } else {
                    alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
                }
            },
            handleCommand(command) {
                var _this = this;
                const that = this;

                if (command == "logout") {
                    this.$confirm('您确定是否退出登录?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        that.back();
                    }).catch(() => {

                    });

                }
                if (command == "info") {
                    //个人信息
                    _this.$router.push("grInfo");
                }
                if (command == "qy") {
                    //企业信息维护
                    _this.$router.push("qyInfo");
                }
            },
            back() {
                //退出

                const _this = this;
                _this.$addStorageEvent(2, "token", '');
                _this.$addStorageEvent(2, "user", '');
                localStorage.clear();
                //跳转到首页
                _this.$message({
                    type: 'success',
                    message: '退出成功!'
                });
                setTimeout(() => {
                    _this.$router.push("/");
                }, 1000)
                $.ajax({
                    url: _this.url + "/api-auth/oauth/remove/token?access_token=" + localStorage.getItem("token"),
                    type: 'POST',
                    success: function (res) {
                        //清除本地的数据
                        //删除token

                    }
                });
            },
            upPwd() {
                this.$router.push("chpwd");
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/common.css";

    .name {
        margin-left: 10px;
        cursor: pointer;
    }
    .main_head{
        float: right;
        margin-right: 30px;
    }
</style>
